<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
    </div>

    <div class="contentBox">
        <el-form ref="form" :model="form" :rules="formRules" label-width="80px">
            <el-form-item label="组织全称" prop="name_long">
                <el-input v-model="form.name_long"></el-input>
            </el-form-item>
            <el-form-item label="组织简称" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="公司网站">
                <el-input v-model="form.website"></el-input>
            </el-form-item>
            <el-form-item label="所在省市">
                <el-cascader placeholder="搜索" :options="options" filterable v-model="form.region" style="width: 100%"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址">
                <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input v-model="form.tel"></el-input>
            </el-form-item>
            <el-form-item label="行业">
                <el-input v-model="form.vocation"></el-input>
            </el-form-item>
            <el-form-item label="简介">
                <el-input type="textarea" v-model="form.content"></el-input>
            </el-form-item>
            <el-form-item label="文化">
                <el-input type="textarea" v-model="form.culture"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width: 100%" @click="onSubmit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            formRules: {
                name_long: [{
                    required: true,
                    message: '请输入组织全称',
                    trigger: 'blur',
                }, ],
                name: [{
                    required: true,
                    message: '请输入组织简称',
                    trigger: 'blur',
                }, ],
                website: [{
                    required: true,
                    message: '请输入组织网站',
                    trigger: 'blur',
                }, ],
                tel: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur',
                }, ],
                address: [{
                    required: true,
                    message: '请输入地址',
                    trigger: 'blur',
                }, ],
            },
            options: [],
        }
    },
    mounted() {
        this.getDetail()
        this.getRegion()
    },
    methods: {
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.http.post('/admin.company/save', this.form).then(() => {
                        this.$message({
                            message: '提交成功!',
                            type: 'success',
                        })
                        // this.goBack()
                    })
                } else {
                    return false
                }
            })
        },
        //返回
        goBack() {
            this.$router.go(-1)
        },
        getDetail() {
            this.http.post('/admin.company/read').then((res) => {
                this.form = res
            })
        },
        getRegion() {
            this.http.post('/admin.areas/index').then((res) => {
                this.options = res
            })
        },
    },
}
</script>

<style lang="less">

</style>
